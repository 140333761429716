import CONST from "@/utils/Constants"; // eslint-disable-line no-unus
import EventBus from '@/config/EventBus';
import PusherHandler from "@/classes/PusherHandler";
import ObjectHelper from "@/helpers/ObjectHelper";
import DateHelper from "@/helpers/DateHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import ApiHelper from "@/helpers/ApiHelper";
import oChatMessage from "@/objects/o-chat-message/o-chat-message.vue";
import oButton from "@/objects/o-button/o-button.vue";
import oInput from "@/objects/o-input/o-input.vue";
import oStatus from "@/objects/o-status/o-status.vue";

export default {
    name: 'mChat',

    components: {
        "o-chat-message": oChatMessage,
        "o-button": oButton,
        "o-input": oInput,
        "o-status": oStatus
    },

    props: {
      id: {
        type: String,
        default: ''
      },
      channable: {
        type: Object,
        default: null
      },
      identifier: {
        type: String,
        default: ''
      },
      barcode: {
        type: String,
        default: ''
      },
      event: String
    },

    data() {
      return {
        style: '0px',
        bottomWidth: 'width: auto;',
        pusherHandler: null,
        message: "",
        channel: null,
        listenChannel: null,
        state: 'online',
        messages: null,
        tempMessages: []
      };
    },

    methods: {

      regenerate: function(){
        ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.BARCODES.GENERATE, [{id: this.channable.id}])).finally(()=> {

        });
      },
      getMessages: function() {
        ApiHelper.get(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.CHATS.DETAIL, [{identifier: this.identifier}]), {headerType: 'basic'})
          .then(response => {
              this.messages = response.data.messages;

              this.setDateMessages();
              this.convertTimes();
              this.setPusherHandler();
              this.setMessagesRead();
          })
          .catch(error => {
              ErrorHelper.catch(error);
          })
          .finally(() => {
            document.getElementById('m-chat__bottom').classList.remove("not-loaded");

            this.setStyle();
          });
      },

      sendMessage: function(){
        let formData = new FormData();
        formData.append('sender', localStorage.getItem(CONST.KEY.USER.ID));
        formData.append('body', this.message);
        formData.append('type', 'message');

        ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.CHATS.SEND, [{identifier: this.identifier}]), formData, {'headerType': 'multipart'}).finally(()=> {
            this.message = '';
        });
      },

      onMessageReceived(data) {
        this.messages.unshift(data.message);
        this.resetDateMessages();
        this.setStyle();
        this.setMessagesRead();
      },

      setPusherHandler() {
        if(this.pusherHandler) {
          this.pusherHandler.disconnect();
          this.pusherHandler.unbind();
          this.pusherHandler = null;
        }

        this.pusherHandler = new PusherHandler(this.identifier, 'message.sent');
        this.pusherHandler.bind(this.onMessageReceived);
        // pusherHandler.activateLogging();
      },

      setMessagesRead() {
        ApiHelper.post(ApiHelper.parseUrlWithParameters(CONST.API.ROUTE.CHATS.READ, [{identifier: this.identifier}]), {headerType: 'basic'})
        .then(() => {
          EventBus.$emit(CONST.EVENTS.CHAT.READ, this.identifier);
        })
        .catch(error => {
            ErrorHelper.catch(error);
        })
        .finally(() => {

        });
      },


      setDateMessages() {
        let $this = this;
        let currentDate;

        this.messages.forEach(function(message, index) {

          let isNewDate = false;

          message.new = false;

          if(message.created_at) {
            if(index === 0) {
              currentDate = $this.convertDate(new Date(message.created_at));
              isNewDate = true;
            }else{
              let newDate = $this.convertDate(new Date(message.created_at))

              if(currentDate !== newDate) {
                isNewDate = true;
                currentDate = newDate;
              }
            }

            if(isNewDate) {
              let body = $this.convertDate(new Date(message.created_at));

              let dateMessage = {
                "type": "date",
                "body": body
              }

              $this.messages.splice(index, 0, dateMessage);
            }
          }
        });
      },

      resetDateMessages() {
        let $currentDate = this.convertDate(this.messages[0].created_at);

        if(this.messages[1].body === $currentDate) {
          this.messages.splice(1, 1);
        }

        let dateMessage = {
          type: "date",
          body: $currentDate
        };

        this.messages.unshift(dateMessage);
      },

      convertDate(date) {
        return DateHelper.weekDayAndDate(date);
      },

      convertTimes() {
        this.messages.forEach(function(message) {
          if(message.created_at) {
            message.created_at_short = DateHelper.time(message.created_at);
          }
        });
      },

      setStyle() {
        let height = 0;

        if(document.getElementById('m-chat__top') && document.getElementById('m-chat__bottom')) {
          height = document.body.clientHeight - (document.getElementById('m-chat__top').clientHeight + document.getElementById('m-chat__bottom').clientHeight + document.getElementById('l-header').clientHeight + 3);
        }

        this.style = "height: " + height + "px;";

        let width = 0;

        if(document.getElementById('m-chat__top') && document.getElementById('m-chat__bottom')) {
          width = document.getElementById('m-chat__top').clientWidth;
        }

        this.bottomWidth = "width: " + width + "px;";
      },

      onClickBack: function() {
        document.getElementById('m-chat__bottom').classList.add("not-loaded");

        this.$store.commit('chatSelected', false);
      }
    },

    watch: {
      identifier: function() {
        this.messages = null;

        this.getMessages();
      }
    },

    computed: {
      canSend: function() {
          return ObjectHelper.notEmpty(this.message);
      },
      messagesInChannel: function() {
        return this.messages;
      },
      getBottomWidth: function() {
        return this.bottomWidth;
      }
    },

    mounted(){

      let $this = this;

      EventBus.$on(CONST.EVENTS.CHAT.SELECTED, function (id) {
        if($this.identifier) {
          if($this.id == id) {
            document.getElementById('m-chat__bottom').classList.remove("not-loaded");
          }
        }
      });


      window.addEventListener('resize', this.setStyle);
    },

  beforeCreate(){
      //console.log('beforeCreate')
  },
  updated() {
      this.setPusherHandler();
  },

  beforeMount(){
    // console.log('beforeMount');
    // console.log(this.identifier);
  },

  beforeUpdate() {
    if(this.pusherHandler) {
      this.pusherHandler.unsubscribe(this.identifier);
      this.pusherHandler.disconnect();
    }
  }
};
