import EventBus from '@/config/EventBus';
import CONST from "@/utils/Constants"; // eslint-disable-line no-unused-vars
import DateHelper from "@/helpers/DateHelper";
import oTag from "@/objects/o-tag/o-tag.vue";

export default {
    name: 'oChat',

    components: {
      "o-tag": oTag
    },

    props: {
      chat: Object
    },

    data() {
      return {
        nUnreadMessages: this.chat.unread
      }
    },

    methods: {

      onClicked: function() {
        this.$store.commit('chatSelected', true);

        EventBus.$emit(CONST.EVENTS.CHAT.SELECTED, this.chat.id);
      }
    },

    computed: {
      newestMessage: function () {
        return this.chat.messages.length > 0 ? this.chat.messages[0] : null;
      },

      time: function() {

        return this.newestMessage !== null ? DateHelper.time(this.newestMessage.created_at) : null;
      },

      date: function() {
        return  this.newestMessage !== null ? DateHelper.weekDayAndDate(this.newestMessage.created_at) : null;
      }
    }
}
