import CONST from "@/utils/Constants"; // eslint-disable-line no-unused-vars
import ObjectHelper from "@/helpers/ObjectHelper";
import oChat from "@/objects/o-chat/o-chat.vue";

export default {
    name: 'mChats',

    components: {
      "o-chat": oChat
    },

    props: {
      chats: Array
    },

    data() {
      return {
        style: '0px',
        filter: ''
      };
    },

    methods: {
      setStyle() {
        let height = 0;

        if(document.getElementById('m-chats__top')) {
          height = document.body.clientHeight - (document.getElementById('m-chats__top').clientHeight + 60);
        }

        this.style = "height: " + height + "px;";
      }
    },

    computed:{
      filteredChats() {

        let _filteredChats = ObjectHelper.objectToArray(Object.assign({}, this.chats));
        let i = 0;

        if(this.filter.length > 1) {
          while (i < _filteredChats.length) {
            if(!_filteredChats[i].channable.barcode.toLowerCase().includes(this.filter.toLowerCase())) {
              _filteredChats.splice(i, 1);
            }else{
              i++;
            }
          }
        }

        return _filteredChats;
      }
    },

    mounted(){
      this.setStyle();

      window.addEventListener('resize', this.setStyle);
    }
};
