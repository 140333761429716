import ObjectHelper from "../../helpers/ObjectHelper";
import oArrow from "@/objects/o-arrow/o-arrow.vue";

export default {
    name: 'oChatMessage',

    components: {
      "o-arrow": oArrow
    },

    props: {
        message: Object,
        barcode: String
    },

    computed: {

        isEventMessage: function(){
            return this.message.type.includes('system');
        },

        isDateMessage: function(){
          return this.message.type.includes('date');
        },

        nameSender: function(){
            return ObjectHelper.notEmpty(this.message.user) ? this.message.user.name : this.barcode;
        },

        direction: function() {
          let _direction;

          if(ObjectHelper.notEmpty(this.message.user)) {
            _direction = 'right';
          }else{
            _direction = 'left';
          }

          return _direction;
        },

        classes:function() {

            let _classes = '';

            if(ObjectHelper.notEmpty(this.message.user)) {
              _classes += 'right ';
            }else{
              _classes += 'left ';
            }

            switch(this.message.type){
                case 'file':
                    _classes += 'document ';
                    break;
                default:
                case 'message':
                case 'system':
                    break;
            }

            if(!this.message.read) {
              _classes += 'new ';
            }

            return _classes;
        },
        processedBody:function() {

            switch(this.message.type){
                case 'file':
                    if(ObjectHelper.exists(this.message.file))
                        return this.message.body+"<br><a href='" + this.message.file.url + "' target='_blank'><i class='material-icons'>insert_drive_file</i><strong>"+this.message.file.file_name+"</strong></a>";

                    return this.message.body+"<br><i class='material-icons'>warning</i><strong>File not found</strong>";

                    default:
                case 'system':
                case 'message':
                    return this.message.body;
            }
        }
    }
}
