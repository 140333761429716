export default {
  name: 'oStatus',

  props: {
    state: { type: String, required: true }
  },

  computed:{
    status: function(){
      let _status;

      if(this.state == 'online') {
        _status = 'online';
      }else{
        _status = 'offline';
      }

      return _status;
    }
  }
};
