export const DateHelper = {

    time: function (isoDatetime) {
      let time = new Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(isoDatetime));

      return time;
    },

    weekDayAndDate: function (isoDatetime) {
      let date = new Intl.DateTimeFormat('default', {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: '2-digit'
      }).format(new Date(isoDatetime));

      return date;
    }
};

export default DateHelper;
