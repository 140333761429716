export default class PusherHandler {

    #channel;
    #pusher;
    #pusherKey;
    #logOutput;

    /**
     * @param {String} identifier The channel identifier
     * @param {String} event The event name, for example: message.sent, campaign.update
     */
   constructor(identifier, event) {

        // reset all listeners
       this.unbind();

       this.identifier = identifier;
       this.event = event;

       this.initPusher();
       this.bindToEvent();
   }

    /**
     * @param {Meth} identifier The channel identifier
     * @param {Function} callback The event name, for example: message.sent
     */
    bind (callback){
        this.log("binded callback", callback);
        this.listeners.push(callback);
    }

    log(responseData){
        if(this.#logOutput === true) {
            // eslint-disable-next-line
            console.log(responseData);
        }
    }

    unbind (){
        this.log("unbinded all listeners", this.listeners);
        this.listeners = [];
    }

    trigger(responseData, context){
        this.log("trigger callback", responseData);

        if(context.listeners !== undefined) {
            context.listeners.forEach((callback) => {
                callback(responseData);
            });
        }
    }

    activateLogging(){
        this.log("Logging activated");
        this.#logOutput = true;
    }

    deactivateLogging(){
        this.log("Logging deactivated");
        this.#logOutput = false;
    }

    initPusher(){
        this.#pusherKey = process.env.VUE_APP_PUSHER_KEY;
        this.#pusher = new window.Pusher(this.#pusherKey, {
            cluster: 'eu',
            forceTLS: true
        });

        this.log('Pusher initialized');
        this.log(this.#pusher);
    }

    unsubscribe(){
      this.#pusher.subscribe(this.identifier);
    }

  disconnect(){
    this.#pusher.disconnect();
  }

    bindToEvent()
    {
      let callback = (response) => {
        this.log(response);
        this.trigger(response, this);
    };

    if (this.#pusher !== undefined && this.event !== undefined) {

      if (this.#channel != null) {
        this.#channel.unbind();
      }

      this.#channel = this.#pusher.subscribe(this.identifier);
      this.#channel.bind(this.event, callback);

      this.log("Subscribed to channel", this.identifier);
      this.log("Listening to event", this.event);
    }
  }
}
