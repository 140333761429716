import EventBus from '@/config/EventBus';
import ApiHelper from '@/helpers/ApiHelper';
import ObjectHelper from '@/helpers/ObjectHelper';
import ErrorHelper from "@/helpers/ErrorHelper";
import CONST from "@/utils/Constants"; // eslint-disable-line no-unus
import LayoutPrivate from '@/layouts/private/layout-private.vue';
import mChats from '@/modules/m-chats/m-chats.vue';
import mChat from '@/modules/m-chat/m-chat.vue';

export default {
  name: 'chats',

  components: {
    'layout-private': LayoutPrivate,
    'm-chats': mChats,
    'm-chat': mChat
  },

  data() {
    return {
      chats: null,
      id: null,
      identifier: null,
      barcode: null,
      channable: null,
      forceUpdate: ''
    };
  },

  methods: {
    getChats: function() {
      ApiHelper.get(CONST.API.ROUTE.CHATS.OVERVIEW, {headerType: 'basic'})
        .then(response => {
            this.chats = response.data;
        })
        .catch(error => {
            ErrorHelper.catch(error);
        })
        .finally(() => {
            this.loading = false;
        });
    },

    setIdentifier: function(id) {
      // reset
      this.forceUpdate = 'update';

      this.id = id;
      this.barcode = ObjectHelper.filterArrayByKeyValue(this.chats, 'id', id)[0].channable.barcode;
      this.channable = ObjectHelper.filterArrayByKeyValue(this.chats, 'id', id)[0].channable;
      this.identifier = ObjectHelper.filterArrayByKeyValue(this.chats, 'id', id)[0].identifier;

      this.forceUpdate = '';
    }
  },

  computed: {
    chatSelected: function() {
      return this.$store.state.chatSelected;
    }
  },

  mounted() {
    let $this = this;

    this.getChats();

    EventBus.$on(CONST.EVENTS.CHAT.SELECTED, function (id) {
      $this.setIdentifier(id);
    });
  }
};
